@import '../../scss/variables';

*[class*='twocol-large'] {
    > .container {
        margin-top: 48px;
    }
}

.container {
    margin: 0 auto 48px;
    max-width: $mobile;

    &[data-alignment='left'] {
        max-width: 100%;

        .title {
            justify-content: flex-start;
            margin-bottom: 0;

            &:after {
                content: none;
            }
        }

        .textContainer {
            align-items: flex-start;
            text-align: left;
        }
    }
}

.title {
    display: flex;
    justify-content: center;
}

.teaser {
    padding-top: 24px;
    width: 100%;
}

.textContainer {
    align-items: center;
    display: flex;
    flex-direction: column;
    text-align: center;
}

.link {
    border-bottom: none;
    padding-top: 32px;
}